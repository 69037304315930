import React, { FC, ReactElement } from 'react';
import MaterialTable, { Action } from '@material-table/core';
import AddLocationMessageDialog from './AddLocationMessageDialog';
import {
  useDeleteLocationMessageMutation,
  useInsertLocationMessageMutation
} from '../../api/generated';
import { Divider, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

export type LocationListItemMessage = {
  id: string;
  title?: string | null;
  message?: string | null;
}

export type LocationListItem = {
  id: string,
  name: string,
  description?: string | null,
  stealthMode?: boolean | null,
  messages?: Array<LocationListItemMessage>;
};

export interface ILocationsListProps {
  data: LocationListItem[];
}

const LocationsList: FC<ILocationsListProps> = ({ data }): ReactElement => {
  const [showAddLocationMessageForm, setShowAddLocationMessageForm] = React.useState(false);
  const [selectedLocationId, setSelectedLocationId] = React.useState<string | undefined>();

  const [insertLocationMessageMutation] = useInsertLocationMessageMutation();
  const [deleteLocationMessageMutation] = useDeleteLocationMessageMutation();

  const formattedData: LocationListItem[] = data.map(location => ({
    id: location.id,
    name: location.name,
    description: location.description,
    stealthMode: location.stealthMode,
    messages: location.messages
  }))
  /**
   * Table configuration
   */
  const tableColumns = [
    { title: 'ID', field: 'id', hidden: true },
    { title: 'Name', field: 'name' },
    { title: 'Kurzbeschreibung', field: 'description' },
    {
      title: 'Versteckt',
      field: 'stealthMode',
      render: function StealthColumnRender(data: LocationListItem, type: "row" | "group") {
        return <>{data.stealthMode ? 'Ja' : 'Nein'}</>;
      }
    },
    {
      title: 'Nachrichten',
      field: 'messages-display',
      render: function MessagesColumnRender(data: LocationListItem, type: "row" | "group") {
        return (
          <List>
            {data.messages?.map(m => (
              <>
                <ListItem key={m.id}>
                  <ListItemText
                    primary={'Titel: ' + m.title}
                    secondary={m.message ? 'Nachricht: ' + m.message : null}
                  />
                  <ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteLocationMessage(m.id)}>
                      <DeleteIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
                <Divider />
              </>
            ))}
          </List>
        )
      }
    }
  ];

  const handleDeleteLocationMessage = async (messageId: string) => {
    await deleteLocationMessageMutation({
      variables: {
        id: messageId
      }
    })
  }

  const handleAddMessageClick = (event: any, data: LocationListItem) => {
    setShowAddLocationMessageForm(true);
    setSelectedLocationId(data.id);
  }

  const handleAddMessageCancel = () => {
    setShowAddLocationMessageForm(false);
  }

  const handleAddMessageAccept = async (locationId: string, startDate: string, endDate: string, title?: string, message?: string) => {
    try {
      await insertLocationMessageMutation({
        variables: {
          locationId: locationId,
          startDate,
          endDate,
          title,
          message
        }
      });

      setShowAddLocationMessageForm(false);
    } catch (e) {
      console.error('Could not add message for location. Error: ' + JSON.stringify(e));
    }
  }

  const tableActions: Array<Action<LocationListItem>> = [
    {
      icon: 'add',
      tooltip: 'Neue Nachricht für diese Location hinzufügen',
      onClick: handleAddMessageClick,
    },
  ];

  /**
   * Render locations list
   */
  return (
    <>
      {(showAddLocationMessageForm && selectedLocationId) && (
        <AddLocationMessageDialog
          onAccept={handleAddMessageAccept}
          onCancel={handleAddMessageCancel}
          locationId={selectedLocationId}
        />
      )}

      <MaterialTable<LocationListItem>
        options={{
          showTitle: false,
          paging: false,
          search: false,
          toolbar: false,
          actionsColumnIndex: 99,
          pageSize: 10,
        }}
        columns={tableColumns}
        data={formattedData}
        actions={tableActions}
      />
    </>

  );
}
  ;

export default LocationsList;
