import React, { FC, ReactElement, useState } from 'react';
import { Box, Drawer, Typography, Grid, Chip } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';

import {
  useObserveProductTypesSubscription,
  useUpsertProductTypeMutation,
} from '../api/generated';
import Loader from '../components/shared/Loader';
import ProductTypesList, {
  ProductTypeListTableItem
} from '../components/products/ProductTypesList';
import ProductTypeForm from '../components/products/ProductTypeForm';
import { IProductType } from '../components/products/types';
import { useGlobalStyles } from '../styles/global';
import GlobalLocation from '../store/globalLocation';
import Error from '../components/shared/Error';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    createProductTypeButton: {
      position: 'absolute',
      right: theme.spacing(10),
      top: theme.spacing(15),
    },
    drawerContent: {
      padding: theme.spacing(5),
      width: '33vw',
      minWidth: '400px',
      overflowY: 'scroll',
    },
  }),
);

/**
 * Page with list of productTypes and possibility to configure product types, products, prices and availabilities.
 */
const Products: FC = (): ReactElement => {
  const globalLocation = GlobalLocation.useContainer();

  const [activeProductType, setActiveProductType] = useState<IProductType>();

  /**
   * Styles
   */
  const classes = {
    ...useGlobalStyles(),
    ...useStyles(),
  };

  /**
   * GraphQL
   */
  const {
    data: productTypesData,
    loading: productTypesLoading,
    error: productTypesError,
  } = useObserveProductTypesSubscription({
    variables: {
      locationId: globalLocation.locationId
    }
  });

  const [upsertProductType] = useUpsertProductTypeMutation();

  /**
   * Handlers
   */
  const handleSelectProductType = (id: string): void => {
    const productType = productTypesData?.productTypes.find(t => t.id === id);
    setActiveProductType(productType);
  };

  const handleProductSubmit = async (data: IProductType): Promise<void> => {
    await upsertProductType({ variables: { data: data } });

    setActiveProductType(undefined);
  };

  const handleCloseDrawer = () => setActiveProductType(undefined);

  const handleOpenCreateForm = () => {
    setActiveProductType({});
  };

  /**
   * Return error component in case of API problems
   */
  if (productTypesError) {
    return <Error error={productTypesError} />;
  }

  /**
   * Show loader until data is available
   */
  const productTypes = productTypesData?.productTypes.map((type): ProductTypeListTableItem => {
    const count = type.products_aggregate.aggregate?.count ?? 0;

    return {
      id: type.id,
      category: type.category,
      name: type.name,
      amountProducts: count,
    }
  });
  if (productTypesLoading || !productTypes) {
    return <Loader />;
  }

  /**
   * Return component
   */
  return (
    <>
      {activeProductType && (
        <>
          <Drawer open={true} onClose={handleCloseDrawer} anchor={'right'}>
            <Box className={classes.drawerContent}>
              <ProductTypeForm
                data={activeProductType}
                onSubmit={handleProductSubmit}
              />
            </Box>
          </Drawer>
        </>
      )}

      <main className={classes.content}>

        <Typography variant={'h1'}>
          Angebote verwalten
          <Grid container spacing={2}>
            <Grid item>
              <Chip
                aria-label='create-new-order'
                label="Neues Angebot hinzufügen"
                icon={
                  <AddIcon />
                }
                onClick={handleOpenCreateForm}
                color='primary'
              />
            </Grid>
          </Grid>
        </Typography>

        <ProductTypesList
          data={productTypes}
          onSelect={handleSelectProductType}
        />
      </main>
    </>
  );
};

export default Products;
