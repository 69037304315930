import React, { FC, ReactElement, useState } from 'react';
import { Drawer, Grid, Chip, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';

import {
  useObserveSeasonsSubscription,
  useUpsertSeasonMutation,
} from '../api/generated';
import Loader from '../components/shared/Loader';
import { useGlobalStyles } from '../styles/global';
import SeasonsList from '../components/seasons/SeasonsList';
import { ISeason } from '../components/seasons/types';
import SeasonForm from '../components/seasons/SeasonForm';
import GlobalLocation from '../store/globalLocation';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    // @todo: Move to global styles
    createSeasonButton: {
      position: 'absolute',
      right: theme.spacing(10),
      top: theme.spacing(15),
    },
  }),
);

/**
 * Page with list of seasons and possibility to configure them (start- and end date + closing dates)
 */
const Seasons: FC = (): ReactElement => {
  const [activeSeason, setActiveSeason] = useState<ISeason>();
  const globalLocation = GlobalLocation.useContainer();
  const locationId = globalLocation.locationId;
  /**
   * Styles
   */
  const classes = {
    ...useGlobalStyles(),
    ...useStyles(),
  };

  /**
   * GraphQL
   */
  const {
    data: seasonsData,
    loading: seasonsLoading,
    error: seasonsError,
  } = useObserveSeasonsSubscription({
    variables: { locationId: locationId },
  });
  const [upsertSeason] = useUpsertSeasonMutation();

  /**
   * Handlers
   */
  const handleSelectSeason = (id: string): void => {
    const season = seasonsData?.seasons?.find(season => season.id === id);

    if (!season) {
      return;
    }

    const mappedSeason: ISeason = {
      id: season.id,
      name: season.name,
      startDate: season.startDate,
      endDate: season.endDate,
    };

    setActiveSeason(mappedSeason);
  };

  const handleSubmit = async (data: ISeason): Promise<void> => {
    const payload = {
      ...data,
      locationId: locationId,
    };

    console.log(payload);

    await upsertSeason({
      variables: {
        data: payload,
      },
    });

    setActiveSeason(undefined);
  };

  const handleCloseDrawer = () => setActiveSeason(undefined);

  const handleOpenCreateForm = () => {
    setActiveSeason({});
  };

  /**
   * Return error component in case of API problems
   */
  if (seasonsError) {
    return <>@todo: Create Error component!{/*<Error />*/}</>;
  }

  /**
   * Show loader until data is available
   */
  const seasons = seasonsData?.seasons;
  if (seasonsLoading || !seasons) {
    return <Loader />;
  }

  /**
   * Return component
   */
  return (
    <>
      {activeSeason && (
        <>
          <Drawer open={true} onClose={handleCloseDrawer} anchor={'right'}>
            <SeasonForm data={activeSeason} onSubmit={handleSubmit} />
          </Drawer>
        </>
      )}

      <main className={classes.content}>
        <Typography variant={'h1'}>
          Saisonen verwalten
          <Grid container spacing={2}>
            <Grid item>
              <Chip
                aria-label='create-new-order'
                label="Neue Saison hinzufügen"
                icon={
                  <AddIcon />
                }
                onClick={handleOpenCreateForm}
                color='primary'
              />
            </Grid>
          </Grid>
        </Typography>

        <SeasonsList data={seasons} onSelect={handleSelectSeason} />
      </main>
    </>
  );
};
export default Seasons;
