import React, { FC, ReactElement, useState } from 'react';
import {
  createStyles,
  makeStyles,
  Theme,
  withStyles,
} from '@material-ui/core/styles';
import { useGlobalStyles } from '../styles/global';
import GlobalDate from '../store/globalDate';
import { useObserveProductTypeAvailabilitiesOverviewSubscription } from '../api/generated';
import {
  Box,
  Button,
  Divider,
  Drawer,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip, Typography,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import SettingsIcon from '@material-ui/icons/Settings';
import WarningIcon from '@material-ui/icons/Warning';
import IconCaretRight from '@material-ui/icons/ArrowRight';
import IconCaretDown from '@material-ui/icons/ArrowDropDown';

import Order from '../components/Order';
import { addHours, format, parseISO } from 'date-fns';
import BookingStatusIcon, {
  BookingStatusEnum,
} from '../components/BookingStatusIcon';
import ProductSettings from '../components/products/ProductSettings';
import GlobalLocation from '../store/globalLocation';

// Configuration
const columnWidth = 2;

enum AvailabilityColorsEnum {
  RED = 'red',
  GREEN = 'green',
  ORANGE = 'orange',
}

const DRAWER_WIDTH = '80vw';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tooltip: {
      fontSize: '1.6em',
    },
    productAvailabilityComponent: {
      position: 'relative',
      borderCollapse: 'collapse',
      borderRight: '1px solid #EEEEEE',
      color: 'white',
      fontWeight: 'normal',
    },
    productButton: {
      fontSize: '1.2em',
      padding: theme.spacing(2),
      textAlign: 'left',
      width: '100%'
    },
    productTypeLabel: {
      maxWidth: '20em',
      alignContent: 'center'
    },
    productTypeButton: {
      fontSize: '1.2em',
      padding: theme.spacing(2),
      textAlign: 'left',
      justifyContent: "flex-start"
    },
    productTypeRow: {
      borderCollapse: 'collapse',
      border: '1px solid #EEEEEE',
      flexWrap: 'nowrap',
    },
    bookingsTable: {
      marginBottom: '2em',
    },
    bookingsContainer: {
      padding: '1em',
      borderLeft: '4em solid lightGrey',
      borderTop: '1em solid lightGrey',
    },
    bookingRow: {
      color: 'grey',
    },
    cancelledBookingRow: {
      '& > .MuiTableCell-root	': {
        color: '#BBBBBB',
        textDecoration: 'line-through',
      },
    },
    drawerPaper: {
      width: DRAWER_WIDTH,
    },
    drawerContainer: {
      overflow: 'auto',
    },
    bookingStatusRow: {
      color: 'black',
    },
    settingsButton: {
      position: 'absolute',
    },
    warningIcon: {
      position: 'absolute',
      right: '0px',
      margin: '12px',
    },
    protectionSizeLabel: {
      minWidth: '10em',
      display: 'inline-block',
    },
    headerDivider: {
      backgroundColor: 'white',
      marginBottom: '1em',
    },
    cardHeader: {
      fontWeight: 'bold',
      fontSize: '1em'
    }
  }),
);

const AvailabilityTooltip = withStyles({
  tooltip: {
    fontSize: '2em',
  },
})(Tooltip);

const Availabilities: FC = (): ReactElement => {
  // Local state
  const [selectedProductType, setSelectedProductType] = useState<string>('');
  const [activeOrderId, setActiveOrderId] = useState<string | null>(null);
  const [activeProductId, setActiveProductId] = useState<string | null>(null);

  // Styles
  const classes = {
    ...useGlobalStyles(),
    ...useStyles(),
  };

  const globalDate = GlobalDate.useContainer().date;
  const globalLocation = GlobalLocation.useContainer();

  const handleSelectProductType = (typeId: string): void => {
    if (selectedProductType === typeId) {
      setSelectedProductType('');
    } else {
      setSelectedProductType(typeId);
    }
  };

  // Handlers
  const handleSelectOrder = (orderId: string) => {
    setActiveOrderId(orderId);
  };

  const {
    data: availabilitiesData,
    loading: availabilitiesLoading,
    error: availabilitiesError,
  } = useObserveProductTypeAvailabilitiesOverviewSubscription({
    variables: {
      locationId: globalLocation.locationId,
      date: globalDate,
    },
  });

  const productTypes = availabilitiesData?.productTypes
    .filter(t => t.products.length > 0);

  if (availabilitiesLoading) {
    return <>Loading...</>;
  }

  if (availabilitiesError) {
    return <>{JSON.stringify(availabilitiesError, null, 2)}</>;
  }

  const bookingsWithProtection = productTypes?.map(type => (
    type.products.map(product => (
      product.bookings
        .reduce((a, b) => (
          (b.includeProtection && b.status !== BookingStatusEnum.CANCELLED) ? a + 1 : a
        ), 0)
    )).reduce((a, b) => a + b, 0)
  )).reduce((a, b) => a + b, 0) ?? 0;

  return (
    <>
      {activeOrderId && (
        <>
          <Drawer
            open={!!activeOrderId}
            onClose={() => setActiveOrderId(null)}
            anchor={'right'}
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <div className={classes.drawerContainer}>
              <Order orderId={activeOrderId} />
            </div>
          </Drawer>
        </>
      )}

      {activeProductId && (
        <>
          <Drawer
            open={!!activeProductId}
            onClose={() => setActiveProductId(null)}
            anchor={'right'}
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <div className={classes.drawerContainer}>
              <ProductSettings productId={activeProductId} date={globalDate} />
            </div>
          </Drawer>
        </>
      )}

      {/* @todo: Move to component */}
      <Box className={classes.content}>
        <Typography variant={'h1'}>
          Verfügbarkeiten verwalten
          
          <Typography variant={'subtitle1'}>
            Buchungen mit Schutzausrüstung: {bookingsWithProtection}
          </Typography>
        </Typography>
        {/*<Typography variant={'h4'}>Gesamt: {bookingsWithProtection}</Typography>*/}

        {/*{productTypes?.map(type => {*/}
        {/*  return (*/}
        {/*    <>*/}
        {/*      <strong>{type.name}</strong><br />*/}
        {/*      {type.products.map(product => {*/}
        {/*        const withProtection = product.bookings.filter(b => b.includeProtection).length;*/}

        {/*        return (*/}
        {/*         <>*/}
        {/*           <span className={classes.protectionSizeLabel}>{product.size}:</span>*/}
        {/*           {withProtection}*/}
        {/*           <br/>*/}
        {/*         </>*/}
        {/*        )*/}
        {/*      })}*/}
        {/*    <br />*/}
        {/*    </>*/}
        {/*  )*/}
        {/*})}*/}


        {productTypes?.map(type => {
          return (
            <React.Fragment key={type.id}>
              {/* ProductTypeAvailabilities component */}
              <Grid container className={classes.productTypeRow}>
                <Grid
                  item
                  xs={4}
                  className={classes.productTypeLabel}
                >
                  <Grid container>
                    <Grid item xs={12}>
                      <Button
                        className={classes.productTypeButton}
                        onClick={() => handleSelectProductType(type.id)}
                        startIcon={selectedProductType !== '' ? <IconCaretDown /> : <IconCaretRight />}
                        fullWidth
                      >

                        <Typography variant='h6'>{type.name}</Typography>
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>

                {type?.products?.map(p => {
                  /***
                   *  BUG !
                   *  @todo: Check why below JSON workaround for deep-clone is necessary.
                   *  snapshotAvailabilities can be an empty array after re-renders without that.
                   */
                  const product = JSON.parse(JSON.stringify(p));
                  const availableBikes =
                    product?.snapshotAvailabilities?.shift()?.availableBikes ??
                    0;
                  const amountBookings =
                    product?.bookings_aggregate?.aggregate?.count ?? 0;
                  const bikesAvailable = product?.bikesAvailable ?? 0;
                  const color: AvailabilityColorsEnum =
                    availableBikes > 1
                      ? AvailabilityColorsEnum.GREEN
                      : availableBikes === 1
                        ? AvailabilityColorsEnum.GREEN /*AvailabilityColorsEnum.ORANGE*/
                        : AvailabilityColorsEnum.RED;

                  return (
                    <Grid
                      item
                      key={product.id}
                      xs={columnWidth}
                      className={classes.productAvailabilityComponent}
                      style={{
                        backgroundColor: color,
                      }}
                    >
                      <Grid container>
                        <Grid item xs={10}>
                          <Box className={classes.productButton}>
                            <Typography variant='body1' className={classes.cardHeader}>{product.size}</Typography>
                            <Divider className={classes.headerDivider} />
                            <Grid container>
                              <Grid item xs={10}>
                                Aktuell verfügbar:
                              </Grid>
                              <Grid item xs={2} style={{ textAlign: 'right' }}>
                                {availableBikes}
                              </Grid>

                              <Grid item xs={10}>
                                Anzahl Buchungen:
                              </Grid>
                              <Grid item xs={2} style={{ textAlign: 'right' }}>
                                {amountBookings}
                              </Grid>
                            </Grid>
                          </Box>
                        </Grid>

                        <Grid item xs={2}>
                          <Button
                            style={{ justifyContent: 'flex-end' }}
                            className={classes.settingsButton}
                            onClick={() => setActiveProductId(product.id)}
                            startIcon={<SettingsIcon style={{ color: 'black', fontSize: '3em', marginLeft: 8 }} />}
                            color='primary'

                          >
                          </Button>
                        </Grid>
                      </Grid>

                      {bikesAvailable - amountBookings !== availableBikes && (
                        <WarningIcon className={classes.warningIcon} />
                      )}
                    </Grid>
                  );
                })}
              </Grid>

              {/* ProductTypeBookings component */}
              {selectedProductType === type.id && (
                <Grid container className={classes.bookingsContainer}>
                  <Grid xs={8}>
                    <TableContainer className={classes.bookingsTable}>
                      <Table stickyHeader size='small'>
                        <TableHead>
                          <TableRow>
                            <TableCell>Status</TableCell>
                            <TableCell>Größe</TableCell>
                            <TableCell>Gewicht</TableCell>
                            <TableCell>Rückgabe</TableCell>
                            <TableCell>Kunde</TableCell>
                            <TableCell />
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {type.products.map(product =>
                            product.bookings.map(booking => {
                              const transactions =
                                booking.bookings_transactions;
                              const duration = parseFloat(
                                booking.order.duration,
                              );
                              const status = booking.status as BookingStatusEnum;

                              let ETAString = '17:00';

                              if (0.5 === duration && transactions?.length) {
                                const shippingDate = parseISO(
                                  transactions.shift()?.updated_at,
                                );

                                ETAString = format(
                                  addHours(shippingDate, 4),
                                  'HH:ii',
                                );
                              }

                              if (1 < duration) {
                                const futureDate = format(
                                  addHours(parseISO(booking.order.endDate), 4),
                                  'dd.MM.',
                                );
                                ETAString = `${futureDate} 17:00`;
                              }

                              if (
                                status === BookingStatusEnum.RETURNED ||
                                status === BookingStatusEnum.CANCELLED
                              ) {
                                ETAString = '';
                              }

                              return (
                                <TableRow
                                  key={booking.id}
                                  className={
                                    status === 'cancelled'
                                      ? classes.cancelledBookingRow
                                      : classes.bookingRow
                                  }
                                >
                                  <TableCell
                                    className={classes.bookingStatusRow}
                                  >
                                    <BookingStatusIcon bookingStatus={status} />
                                  </TableCell>

                                  <TableCell>{product.size}</TableCell>

                                  <TableCell>
                                    {booking.riderWeight}kg {booking.riderName ? <>({booking.riderName})</> : (<></>)}
                                  </TableCell>

                                  <TableCell>{ETAString}</TableCell>

                                  <TableCell>
                                    {booking.order.customer.name}
                                  </TableCell>

                                  <TableCell>
                                    <Button
                                      onClick={() =>
                                        handleSelectOrder(booking.order.id)
                                      }
                                    >
                                      <EditIcon />
                                    </Button>
                                  </TableCell>
                                </TableRow>
                              );
                            }),
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                </Grid>
              )}
            </React.Fragment>
          );
        })}
      </Box>
    </>
  );
}

export default Availabilities;